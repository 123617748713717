import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import AdbIcon from '@mui/icons-material/Adb';
import sectionPaths from '../component/sectionPaths';

const pages = [
    {
        title: "HOME",
        path: "/",
        Width: "10%"

    }, {
        title: 'SOLUTIONS',
        path: "/solutions",
        Width: "30%"
    }, {
        title: 'ABHA',
        path: "/abha",
        Width: "15%"
    }, {
        title: 'CONTACT US',
        path: "/contactus",
        Width: "25%"

    }];
const socialMediaIcons = [
    { icon: <img src='/logos/linkdn.png' height="28" alt='Scanbo LinkedIn Page' />, href: 'https://www.linkedin.com/company/scanbo/' },
    { icon: <img src='/logos/twitter-new.png' height="28" alt='Scanbo Twitter Page' />, href: 'https://twitter.com/ScanboOfficial' },
    { icon: <img src='/logos/facebook.png' height="39" alt='Scanbo Facebook Page' />, href: 'https://www.facebook.com/ScanboOfficial' },
    { icon: <img src='/logos/instagram.png' height="28" alt='Scanbo Instagram Page' />, href: 'https://www.instagram.com/scanboofficial/' },
    { icon: <img src='/logos/youtube.svg' height="40" alt='Scanbo Instagram Page' />, href: 'https://www.youtube.com/@scanboai' }
];

const useStyles = makeStyles({

    container: {
        padding: "10px 20px !important",
        maxWidth: "1360px !important",
        margin: "0 auto !important"
    },
    menu: {
        // flex: "auto",
        textAlign: "center",
        justifyContent: "center",
        color: "#000",
        textAlign: "left",
        letterSpacing: " 0.3px",
        webkitTextStrokeWidth: " 0.7px",
        webkitTextStrokeColor: "#000",
        border: "0 solid #000",
        borderTopWidth: "1.5px",
        paddingTop: "8px",
        fontWeight: "300",
        textDecoration: "none",
        marginLeft: "5px",
        marginRight: "20px",
        padding: "8px 0 5px",
        fontSize: "16px"
    },
    menuResponsive: {
        "& div": {
            width: "100%",
            top: "9% !important",
            boxShadow: "none !important"
        }
    },
    Toolbar: {
        justifyContent: "space-between !important"
    }
})

function ResponsiveAppBar({ activeSection, onSectionChange }) {
    const classes = useStyles();

    const [anchorElNav, setAnchorElNav] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [elevateAppBar, setElevateAppBar] = React.useState(false);


    const handleScroll = () => {
        setElevateAppBar(window.scrollY > 0);
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(!anchorElNav); // Toggle the menu open state
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(false);
    };

    const handleMenuItemClick = (path) => {
        onSectionChange(path)
        handleCloseNavMenu();
    };
    return (
        <AppBar position="sticky" sx={{
            zIndex: (theme) => theme.zIndex.tooltip + 1,
            backgroundColor: '#fff',
            color: "#000",
            boxShadow: elevateAppBar ? '0 2px 5px rgba(0, 0, 0, 0.05)' : 'none',
            transition: 'box-shadow 0.3s',
        }}>
            <Container
                className={classes.container}>
                <Toolbar disableGutters className={classes.Toolbar}>

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src='/logos/scanbo-img-in.svg' alt='Scanbo India Private Limited' height={75} />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}  >
                        <Menu
                            className={classes.menuResponsive}
                            id="menu-appbar"
                            style={{ width: "100%" }}
                            anchorEl={anchorElNav}
                            // anchorOrigin={{
                            //     vertical: 'bottom',
                            //     horizontal: 'left',
                            // }}
                            // transformOrigin={{
                            //     vertical: 'top',
                            //     horizontal: 'left',
                            // }}
                            open={anchorElNav}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                transform: " translateY(0px) translateX(0px)",
                                transition: "transform 400ms ease 0s",
                                width: '100% !important',
                            }}
                        >
                            {anchorElNav &&

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}>
                                    {sectionPaths.map((page, index) => (
                                        <MenuItem key={index} onClick={handleCloseNavMenu} >
                                            <Link
                                                className={classes.menu}
                                                rel="canonical"
                                                to={page.path}
                                                style={{
                                                    color: activeSection === page.path ? 'black' : '#b4b4b4', // Red if selected, black otherwise
                                                    borderTopColor: activeSection === page.path ? 'black' : '#b4b4b4'

                                                }}
                                                onClick={() => handleMenuItemClick(page.path)}
                                            >

                                                {page.title}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                    <Box sx={{
                                        my: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        width: '100% !important', // Social icons container full width
                                    }}>
                                        {socialMediaIcons.map((socialIcon, index) => (
                                            <a
                                                key={index}
                                                href={socialIcon.href}
                                                color="inherit"
                                                style={{ margin: "10px" }}
                                                target="_blank" // Opens the link in a new tab
                                                rel="nofollow noopener noreferrer"

                                            >
                                                {socialIcon.icon}
                                            </a>
                                        ))}
                                    </Box>
                                    <Link to="/download" className='link' rel="canonical">
                                        <Button variant="contained" className="DownloadButton">
                                            Download App
                                        </Button>
                                    </Link>
                                </Box>
                            }
                        </Menu>
                    </Box>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none', width: "100vw" },
                            flexGrow: 1,

                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src='/logos/scanbo-img-in.svg' alt='Scanbo India Private Limited' height={75} />
                    </Typography>

                    <IconButton
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },

                        }}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                    // color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', maxWidth: "42%" } }}>
                        {sectionPaths.map((page, index) => (
                            <Link
                                rel="canonical"
                                className={classes.menu}
                                key={index}
                                to={page?.path}
                                style={{
                                    color: activeSection === page.path ? 'black' : '#b4b4b4', // Red if selected, black otherwise
                                    borderTopColor: activeSection === page.path ? 'black' : '#b4b4b4',
                                    minWidth: page.Width,
                                    textAlign: "center"
                                }}
                                onClick={() => handleMenuItemClick(page.path)}
                            // sx={{ my: 2, color: 'black', display: 'block' }}
                            >
                                {page?.title}
                            </Link>
                        ))}
                    </Box>


                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>

                        {socialMediaIcons.map((socialIcon, index) => (
                            <a
                                key={index}
                                href={socialIcon.href}
                                color="inherit"
                                style={{
                                    margin: "10px"
                                }}
                                target="_blank" // Opens the link in a new tab
                                rel="nofollow noopener noreferrer"

                            >
                                {socialIcon.icon}
                            </a>
                        ))}
                        <Link to="/download" className='link' rel="canonical">
                            <Button variant="contained" className="DownloadButton" >
                                Download App
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </Container >
        </AppBar >
    );
}
export default ResponsiveAppBar;